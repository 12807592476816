require('./bootstrap');

/* Form SUBMIT disable button. */
/* =========================================================== */
$(document).on('submit', 'form', function() {

    let btn = $('button[type=submit]', this);

    if(!btn.hasClass('prevent-loading')) {

        if($(this).hasClass('confirm')) {

            if(confirm('Are you sure?')) {
                $('button[type=submit]', this).attr('disabled', true).prepend('<i class="fas fa-circle-notch fa-spin mr-1"></i>');
                return true;
            } else {
                $('button[type=submit]', this).attr('disabled', false);
                return false;
            } //end if

        } else {
            $('button[type=submit]', this).attr('disabled', true).prepend('<i class="fas fa-circle-notch fa-spin mr-1"></i>');
        } //end if

    } //end if

    return;
});

/* Select2 */
/* =========================================================== */
$(function () {
    $('.select2').select2();

    $('.select2').one('select2:open', function(e) {
        $('input.select2-search__field').prop('placeholder', 'Search...');
    });
});

/* Toastr config options. */
/* =========================================================== */
toastr.options = {
    'positionClass': 'toast-bottom-right',
    'onclick': null,
    'showDuration': '300',
    'hideDuration': '1000',
    'timeOut': '5000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
}

/* BS4 Tooltip */
/* =========================================================== */
$(function () {
    $('body').tooltip({ selector: '[data-toggle=tooltip]' });
});
